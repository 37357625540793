<template>
    <div class="Home">
      <page-header/>

      <section class="uk-section uk-padding-remove">
        <div class="uk-container">

          <div v-if="error!==null" class="uk-alert-warning" uk-alert>
            <p v-html="error" class="uk-text-center"></p>
          </div>

          <vue-good-wizard
            ref="wizard"
            :steps="steps"
            :onNext="nextClicked"
            :onBack="backClicked">
            <div slot="page1">
              <div>
                <header class="uk-text-center">
                  <h1 class="uk-heading-primary">Price</h1>
                  <p
                    class="uk-width-3-5 uk-margin-auto"
                  >Our Product is the easiest way for teams to track their works progress. Our advance plans give you
                    more
                    tools to get the job done.</p>
                </header>
                <div
                  class="uk-grid uk-grid-small uk-child-width-1-3@m uk-margin-medium-top uk-grid-match"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div > .uk-card; delay: 200"
                  data-uk-grid
                >
                  <!-- price -->
                  <div>
                    <div :class="{ selecteditem: vps == 1 }"
                         class="uk-card uk-card-default uk-card-hover uk-flex uk-flex-column"
                         data-uk-scrollspy-class="uk-animation-slide-left-small"
                    >
                      <div class="uk-card-header uk-text-center">
                        <h4 class="uk-text-bold">PERSONAL</h4>
                      </div>
                      <div class="uk-card-body uk-flex-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                          <span style="font-size: 3rem; font-weight: 200; line-height: 1em">
                            <span style="font-size: 0.5em"  class="uk-text-bold" >$9.99</span>
                          </span>
                        </div>
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                        <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                          <span style="font-size: 0.4em">P</span>10000
                          <!-- <small>.99</small> -->
                        </span>
                        </div>
                        <div
                          class="uk-text-small uk-text-center uk-text-muted"
                        >
                        </div>
                        <!-- <ul>
                          <li>2 users included</li>
                          <li>1 GB of storage</li>
                          <li>A beer jar</li>
                        </ul> -->
                      </div>
                      <div class="uk-card-footer">
                        <button
                          @click="selectvps(1)"
                          href="#"

                          class="uk-button uk-button-primary uk-width-1-1"
                        >SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /price -->
                  <!-- price -->
                  <div>
                    <div :class="{ selecteditem: vps == 2 }"
                         class="uk-card uk-card-default uk-card-hover uk-flex uk-flex-column">
                      <div class="uk-card-header uk-text-center">
                        <h4 class="uk-text-bold">PROFESSIONAL</h4>
                      </div>
                      <div class="uk-card-body uk-flex-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                          <span style="font-size: 3rem; font-weight: 200; line-height: 1em">
                            <span style="font-size: 0.5em"  class="uk-text-bold" >$19.99</span>
                          </span>
                        </div>
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                          <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                            <span style="font-size: 0.4em">p</span>20000
                            <!-- <small>.99</small> -->
                          </span>
                        </div>
                        <!-- <div
                          class="uk-text-small uk-text-center uk-text-muted"
                        >Per member billed annually
                        </div>
                        <ul>
                          <li>10 users included</li>
                          <li>5 GB of storage</li>
                          <li>Email support</li>
                          <li>A beer jar with beer inside!</li>
                        </ul> -->
                      </div>
                      <div class="uk-card-footer">
                        <button
                          @click="selectvps(2)"
                          href="#"

                          class="uk-button uk-button-primary uk-width-1-1"
                        >SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /price -->
                  <!-- price -->
                  <div>
                    <div :class="{ selecteditem: vps == 3 }"
                         class="uk-card uk-card-default uk-flex uk-card-hover uk-flex-column"
                         data-uk-scrollspy-class="uk-animation-slide-right-small"
                    >
                      <div class="uk-card-header uk-text-center">
                        <h4 class="uk-text-bold">CORPORATE</h4>
                      </div>
                      <div class="uk-card-body uk-flex-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                          <span style="font-size: 3rem; font-weight: 200; line-height: 1em">
                            <span style="font-size: 0.5em"  class="uk-text-bold" >$29.99</span>
                          </span>
                        </div>
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                          <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                            <span style="font-size: 0.5em">p</span>30000
                          </span>
                        </div>
                        <!-- <div
                          class="uk-text-small uk-text-center uk-text-muted"
                        >Per member billed annually
                        </div>
                        <ul>
                          <li>Unlimited users</li>
                          <li>Unlimited storage</li>
                          <li>Email support</li>
                          <li>Help center access</li>
                        </ul> -->
                      </div>
                      <div class="uk-card-footer">
                        <button
                          @click="selectvps(3)"
                          href="#"

                          class="uk-button uk-button-primary uk-width-1-1"
                        >SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /price -->
                </div>
              </div>
            </div>
            <div slot="page2">
              <div>
                <h1 >somethings</h1>
              </div>
            </div>

          </vue-good-wizard>


        </div>

        <div id="modal-success" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Thank you</h2>
            </div>
            <div class="uk-modal-body">
              <p v-html="message"></p>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <router-link
                tag="a"
                class="uk-button uk-button-primary uk-modal-close"
                :to="{
              name: 'home'
            }"
              >Okey
              </router-link>

            </div>
          </div>
        </div>
      </section>

      <page-footer/>
    </div>

</template>

<script>
  import GoodWizard from '@/components/GlobViews/Wizard'
  const PageHeader = () => import("./StudentHeaderNew.vue");
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    name: 'Home',
    components: {
      'vue-good-wizard': GoodWizard,
    PageHeader,
    PageFooter
    },

    data() {
      return {
        message: '',
        vps: 0,
        steps: [
          {
            label: 'Price',
            slot: 'page1',
          },
          {
            label: 'Agreement',
            slot: 'page2',
            options: {
              nextDisabled: false,
              nextStepLabel: 'Daraah',
              finalStepLabel: 'I agree'
            }
          }
        ],

      }
    },
    watch: {
    },
    created() {

    },
    methods: {
      nextClicked(currentPage) {
        // const _this = this;
        // _this.$refs.wizard.goNext(true);
        console.log('next clicked', currentPage)

        return true; //return false if you want to prevent moving to next page
      },
      backClicked(currentPage) {
        console.log('back clicked', currentPage);
        return true; //return false if you want to prevent moving to previous page
      },
      selectvps: function (value) {
        this.vps = value
      },
    }
  }
</script>

<style scoped>
  .selecteditem {
    border: 1px solid green;
  }
</style>
